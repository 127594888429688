import React, { Fragment } from "react";
import { RouteData, SiteData } from "react-static";
import styled from "@emotion/styled";
import Slider from "react-slick";

import HeroQuote from "../components/hero-quote";
import Section from "../components/section";
import Down from "../components/next-section-arrow";
import Seo from "../components/seo";

const FaChevronLeft = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 320 512"
    style={{ color: "orangered" }}
    height="1em"
    width="1em"
  >
    <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" />
  </svg>
);
const FaChevronRight = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 320 512"
    style={{ color: "orangered" }}
    height="1em"
    width="1em"
  >
    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
  </svg>
);

const BQ = styled.blockquote`
  max-width: 720px;
  font-family: "Lyon", serif;
  font-size: 16pt;
  line-height: 1.5;
  margin: 0 auto;
`;

const Caption = styled.span`
  max-width: 720px;
  font-family: "Lyon", serif;
  font-size: 10pt;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0 auto;
  &:before {
    content: " —";
  }
`;

const SliderLeftArrow = ({ className, onClick }) => (
  <button className={className} onClick={onClick}>
    <FaChevronLeft color="orangered" />
  </button>
);

const SliderRightArrow = ({ className, onClick }) => (
  <button className={className} onClick={onClick}>
    <FaChevronRight color="orangered" />
  </button>
);

class Home extends React.Component {
  render() {
    const { copy, siteData, meta } = this.props;
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplaySpeed: 12000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      nextArrow: <SliderRightArrow />,
      prevArrow: <SliderLeftArrow />
    };
    return (
      <Fragment>
        <Seo
          url="https://inotherwords.id"
          siteTitle={siteData.data.title}
          pageTitle={meta.title}
          description={meta.description}
          image={meta.image}
          siteFBAppID={siteData.data.fbappid}
          userTwitter={siteData.data.twitter}
        />
        <Section renderButton={() => <Down />}>
          <HeroQuote>{copy.hero.content}</HeroQuote>
        </Section>
        <Section
          id="testimonials"
          title={copy.testimonials.data.title}
          renderButton={() => <Down />}
        >
          <Slider {...sliderSettings}>
            {copy.testimonials.data.content.map(({ quote, author }, idx) => (
              <figure key={idx}>
                <BQ>
                  {quote}
                  <Caption>{author}</Caption>
                </BQ>
              </figure>
            ))}
          </Slider>
        </Section>
        <Section title={copy.why.data.title}>
          <BQ>{copy.why.content}</BQ>
        </Section>
      </Fragment>
    );
  }
}

export default () => (
  <SiteData>
    {siteData => (
      <RouteData>
        {routeData => <Home {...routeData} siteData={siteData} />}
      </RouteData>
    )}
  </SiteData>
);
