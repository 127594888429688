import React from "react";
import styled from "@emotion/styled";

const Outer = styled.section`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const Inner = styled.div`
  margin-top: 4rem;
  min-height: calc(100vh - 4rem);
  height: 100%;
  width: calc(100vw - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 850px;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Title = styled.h2`
  height: 2rem;
  margin-top: 4rem;
  padding: 0;
`;

const InnerWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${props =>
    props.innerDirection ? props.innerDirection : "column"};
  ${props => (props.innerWrap ? `flex-wrap: wrap;` : "")} align-items: center;
  justify-content: ${props => (props.center ? "center" : "flex-start")};
  padding-top: 2rem;
  width: ${props => (props.innerFull ? `100vw` : `100%`)};
`;

export default ({
  render,
  innerDirection,
  innerWrap = false,
  innerFull = false,
  center = false,
  children,
  title = "",
  renderButton = false,
  ...props
}) => (
  <Outer {...props}>
    <Inner notitle={title === ""}>
      {title ? <Title>{title}</Title> : null}
      <InnerWrap
        {...{
          innerDirection,
          innerWrap,
          innerFull,
          center
        }}
      >
        {render ? render() : children}
      </InnerWrap>
      {renderButton ? renderButton() : null}
    </Inner>
  </Outer>
);
