import React from "react";
import styled from "@emotion/styled";

const Quote = styled.blockquote`
  font-family: "Lyon", serif;
  text-align: center;
  line-height: 1.5;
  font-size: 26px;
`;

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: calc(100vw - 2rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.blockquote`
  text-align: center;
  font-family: "Lyon", georgia, serif;
  line-height: 1.5;
  section {
    font-size: 13pt;
    margin-top: 2rem;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  font-size: 26px;
`;

export default props => (
  <Wrapper>
    <Quote {...props} />
  </Wrapper>
);
