import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const FaAngleDown = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 320 512"
    style={{ color: "orangered" }}
    height="1em"
    width="1em"
  >
    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
  </svg>
);

const bounce = keyframes`
  from, 10%, 28%, 40%, to {
    transform: translate3d(0,0,0);
  }

  20%, 23% {
    transform: translate3d(0, -30px, 0);
  }

  35% {
    transform: translate3d(0, -15px, 0);
  }

  45% {
    transform: translate3d(0,-4px,0);
  }
`;

const Arrow = styled.a`
  margin-top: 1rem;
  margin-bottom: 4rem;
  justify-self: flex-end;
  animation: ${bounce} 2s ease infinite;
  transform-origin: center bottom;
  &:hover {
    animation-play-state: paused;
  }
`;

export default props => (
  <Arrow {...props}>
    <FaAngleDown color="orangered" />
  </Arrow>
);
