import React from "react";
import { Head } from "react-static";

const SEO = props => {
  const pageTitle = props.pageTitle
    ? `${props.siteTitle} | ${props.pageTitle}`
    : props.siteTitle;
  const seoImage = props.image || "/media/ICON.png";
  const imgSrc = `https://inotherwords.id${seoImage}`;
  return (
    <Head>
      <title>{pageTitle}</title>

      {/* General tags */}
      <meta
        name="description"
        content={props.description || "In Other Words Website"}
      />
      <meta name="image" content={imgSrc} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={pageTitle} />
      <meta
        property="og:description"
        content={props.description || "In Other Words Website"}
      />
      <meta property="og:image" content={imgSrc} />
      <meta
        property="fb:app_id"
        content={props.siteFBAppID ? props.siteFBAppID : ""}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={props.userTwitter ? props.userTwitter : ""}
      />
      <meta name="twitter:title" content={pageTitle} />
      <meta
        name="twitter:description"
        content={props.description || "In Other Words Website"}
      />
      <meta name="twitter:image" content={imgSrc} />
    </Head>
  );
};

export default SEO;
